import React from 'react';
import { BrowserRouter, Route } from "react-router-dom";
import { Provider } from 'react-redux';
import { createStore } from 'redux';

import reducers from './reducer';

import LoginPage from './view/login';
import MainPage from './view/main';
import RegisterPage from './view/register';
import DepositPage from './view/deposit';
import WithdrawPage from './view/withdraw';
import ReferPage from './view/refer';
import ForgetpasswordPage from './view/forgetpassword';


function App() {

  return (
    <>
      <Provider store={createStore(reducers)}>
        <BrowserRouter>
          <Route exact path="/register" component={RegisterPage} />
          <Route exact path="/signin" component={LoginPage} />
          <Route exact path="/deposit" component={DepositPage} />
          <Route exact path="/withdraw" component={WithdrawPage} />
          <Route exact path="/refer" component={ReferPage} />
          <Route exact path="/forgetpassword" component={ForgetpasswordPage} />
          <Route exact path="/" component={MainPage} />
        </BrowserRouter>
      </Provider>
    </>
  );
}

export default App;
