import React from 'react';
import { Row, Col, Form, Button, Alert, Modal, Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import  { Redirect } from 'react-router-dom';
import _ from 'lodash';
import { ReCaptcha, loadReCaptcha } from 'react-recaptcha-v3';

import '../assets/css/register.css';

import request from '../libs/request';

import Layout from '../component/layout';


import Cookies from 'universal-cookie';

import config from '../config';
const CONFIG = config[ process.env.NODE_ENV || 'development' ];

const cookies = new Cookies();

//  client 6Lf6QesUAAAAALQMY3uad5iJzUs-6auvfl4ysoCY
//  server 6Lf6QesUAAAAAD9Y0D3P-kAM1CtcJswa6BI1RdWc



class Login extends React.Component {
  constructor(props){
    super(props);

    let reference = '';
    let refer = this.props.location.search.replace('?','');
    if(this.props.location.search){
      if(refer.substring(0,1).toLowerCase() === '@'){
        if(cookies.get('ufasystemdownline')) cookies.remove('ufasystemdownline');
        cookies.set('ufasystemdownline', { code:refer.replace('@','') } );
      }
      else reference = refer;
    }


    this.state = {
      loading: false,
      login: false,
      phone:'',
      firstname:'',
      lastname:'',
      banks: [],
      bank:'KBANK',
      acceptpromotion: 'yes',
      channel:'',
      banknumber:'',
      lineid:'',
      reference: reference, 
      intro:'',
      noAcceptPromotion:'',
      showModal: false,
      finish:false,
      channels: [],
      promotion:{},
      verifyCaptcha:false,
    }

    this.submitForm = this.submitForm.bind(this);
    this.changeValue = this.changeValue.bind(this);
    this.hideModal = this.hideModal.bind(this);
    this.selectPromotion = this.selectPromotion.bind(this);
    this.verifyCallback = this.verifyCallback.bind(this);
  }

  componentDidMount(){
    
    

    if(cookies.get('ufasystem')) cookies.remove('ufasystem');

    request.get('system').then(response => {

      if(response.data && response.data.status_code === 200){

        let result = response.data;
        if(result.success){
          loadReCaptcha( CONFIG.recaptcha );

          let channels = result.data.system.configuration.registerChannel;

          this.setState({ 
            intro: result.data.system.configuration.registerIntro, 
            noAcceptPromotion: result.data.system.configuration.message?result.data.system.configuration.message.noAcceptPromotion:'', 
            channels: channels,
            channel: '', 
            banks: result.data.system.banks,
          })
        }
        else
        {
          console.log(result.message);
          if(cookies.get('ufasystemdownline')) cookies.remove('ufasystemdownline');
          this.setState({ finish:true })
        }

      }
      else
      {
      }

    })
    .catch(console.error)
  }

  changeValue(e){
    let id = e.currentTarget.id.split('_').pop();
    let data = {...this.state};
    let value = e.currentTarget.value || '';
    data[id] = value;
    this.setState( data );
  }

  submitForm(e){
    e.preventDefault();

    this.setState({ loading:true }, () => {

      request.post('member/register', this.state).then(response => {
        if(response.data && response.data.status_code === 200){

          let result = response.data;
 
          this.setState({ loading:false }, () => {

            if(result.success){
              this.setState({ showModal:true }, () => {

              })
            }
            else alert(result.message);

          });
          
        }
        else
        {
          console.log('Cannot register');
        }
      })

    })
  }


  hideModal(){
    this.setState({ showModal:false, finish:true }, () => {
    });
  }

  selectPromotion(e){
    if(e.currentTarget.value){
      let channel = _.find(this.state.channels, { code:e.currentTarget.value });
      if(channel){
        let data = { channel:channel.code };
        if(channel.promotion && channel.promotion.id) data.promotion = channel.promotion;
        this.setState(data);
      }
    }
    else this.setState({ promotion:{}, channel:'' })  
  }

  verifyCallback = (recaptchaToken) => {
    // Here you will get the final recaptchaToken!!!  
    //console.log(recaptchaToken, "<= your recaptcha token")
    this.setState({ verifyCaptcha:true })
  }

  render() {
    const { loading, intro, noAcceptPromotion, showModal, banks, channels, promotion, verifyCaptcha } = this.state;

    return (
      <>

      <Layout>
      <Row className="justify-content-md-center ">
        <Col lg={6} sm={10} xs={12} className="register-form">
          {intro &&
          <Alert variant="warning">
            <div  dangerouslySetInnerHTML={{__html: intro}}></div>
          </Alert>
          }
          
          <Form onSubmit={this.submitForm}>

            <Row>
              <Col sm={6}>
                <Form.Group controlId="register_phone">
                  <Form.Label>เบอร์โทรศัพท์มือถือ</Form.Label>
                  <Form.Control type="text" placeholder="กรุณากรอก เบอร์โทรศัพท์มือถือ 10 หลัก" pattern="^[0-9]+$" title="กรุณากรอก เบอร์โทรศัพท์มือถือ 10 หลัก" minLength={10} maxLength={10} required onChange={this.changeValue}/>
                </Form.Group>
              </Col>
            </Row>

            <Form.Row>
                <Form.Group as={Col} sm controlId="register_firstname">
                  <Form.Label>ชื่อจริง</Form.Label>
                  <Form.Control type="text" placeholder="กรุณากรอก ชื่อจริงภาษาไทย" pattern="^[ก-๏\s]+$" title="กรุณากรอกชื่อ ภาษาไทย" required onChange={this.changeValue} />
                </Form.Group>
                <Form.Group as={Col} sm controlId="register_lastname">
                  <Form.Label>นามสกุล</Form.Label>
                  <Form.Control type="text" placeholder="กรุณากรอก นามสกุลภาษาไทย" pattern="^[ก-๏\s]+$" title="กรุณากรอกชื่อ ภาษาไทย" required onChange={this.changeValue} />
                </Form.Group>
            </Form.Row>


            <Form.Row>
                <Form.Group as={Col} sm controlId="register_bank">
                  <Form.Label>ธนาคาร</Form.Label>
                  <Form.Control as="select" required onChange={this.changeValue}>
                    {banks.map((bank,i) => <option key={i} value={bank.code}>{bank.name}</option>)}
                  </Form.Control>
                </Form.Group>
                <Form.Group as={Col} sm controlId="register_banknumber">
                  <Form.Label>เลขบัญชีธนาคาร</Form.Label>
                  <Form.Control type="text" placeholder="กรุณากรอก เลขบัญชีธนาคาร" minLength={10} maxLength={13} pattern="^[0-9]+$" title="เลขบัญชีธนาคาร จะต้องเป็นตัวเลขเท่านั้น" required onChange={this.changeValue}/>
                </Form.Group>
            </Form.Row>

            <Form.Row>
                <Form.Group as={Col} controlId="register_lineid">
                  <Form.Label>Line ID</Form.Label>
                  <Form.Control type="text" placeholder="กรุณากรอก Line ID ถ้าไม่มีเว้นว่างไว้"  onChange={this.changeValue}/>
                </Form.Group>
                <Form.Group as={Col} sm controlId="register_channel">
                  <Form.Label>มาจากช่องทาง</Form.Label>
                  <Form.Control as="select" required onChange={this.selectPromotion} title="กรุณาเลือกช่องทางที่คุณมาสมัคร">
                    <option value=""></option>
                    {channels.map((channel,i) => <option key={i} value={channel.code}>{channel.title}</option>)}
                  </Form.Control>
                </Form.Group>
            </Form.Row>

            {(promotion && promotion.id) &&
            <>

            {promotion.thumbnail && <img src={promotion.thumbnail} className="img-fluid" alt="" />}

            {promotion.description && 
            <Card className="mb-3 register-promotion">
              <Card.Body>{promotion.description}</Card.Body>
            </Card>
            }

            <Form.Row className="mt-3">
                <Form.Group as={Col} sm controlId="register_acceptpromotion">
                  <Form.Label>สนใจรับโปรนี้หรือไม่ ?</Form.Label>
                  <Form.Control as="select" required onChange={this.changeValue}>
                    <option value="yes">รับ</option>
                    <option value="no">ไม่รับ</option>
                  </Form.Control>
                </Form.Group>
            </Form.Row>
            {(noAcceptPromotion && this.state.acceptpromotion === 'no') &&
            <Alert variant="warning">
              <div  dangerouslySetInnerHTML={{__html: noAcceptPromotion}}></div>
            </Alert>
            }
            
            </>
            }

            <ReCaptcha
              sitekey={CONFIG.recaptcha}
              action='register'
              verifyCallback={this.verifyCallback}
            />

            {loading?(
            <div className="text-center">
              <FontAwesomeIcon icon={faSpinner} size="3x" spin /> <br/>
              กรุณารอสักครู่...
            </div>
            ):(

              <Button variant="primary" type="submit" block size="lg" disabled={!verifyCaptcha}>
              สมัครสมาชิก
              </Button>
            )}

          </Form>
        </Col>
      </Row>
      </Layout>
      
      <Modal
        show={showModal}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={this.hideModal}
      >
        <Modal.Body>
          <h4 className="text-success">สมัครสมาชิกเรียบร้อยแล้ว</h4>
          <p>
            ระบบได้ส่ง <span className="text-info">ชื่อผู้ใช้ระบบ</span> และ <span className="text-info">รหัสผ่าน</span> ไปยังเบอร์มือถือ <span className="text-info">{this.state.phone || ''}</span> ทาง SMS เรียบร้อยแล้ว
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button size="sm" variant="secondary" onClick={this.hideModal}>ปิด</Button>
        </Modal.Footer>
      </Modal>

      {this.state.finish && <Redirect to='/signin'  /> }
      </>
    );
  }
}


export default Login;