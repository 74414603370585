import React from 'react';
import { Row, Col, Card, Button, Modal, Table, Image } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import Cookies from 'universal-cookie';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner, faInfoCircle } from '@fortawesome/free-solid-svg-icons'

import '../assets/css/main.css'

import request from '../libs/request';
import Layout from '../component/layout';

const cookies = new Cookies();

const LOGO = {
  'SCB': require('../assets/images/SCB.png'),
  'BAY': require('../assets/images/BAY.png'),
}

class Deposit extends React.Component {
  constructor(props) {
    super(props);

    let data = cookies.get('ufasystem');

    this.state = {
      loading: false,
      logined: data ? true : false,
      username: data ? data.username : '',
      system: {},
      member: {},
      logs: [],
      showModal: false,
    }

    this.hideModal = this.hideModal.bind(this);
  }

  componentDidMount() {

    this.setState({ loading: true }, () => {

      request.get('page/deposit').then(response => {
        if (response.data && response.data.status_code === 200) {

          let result = response.data;

          this.setState({ loading: false }, () => {

            if (result.success) {


              this.setState({
                showModal: true,
                member: result.data.member,
                system: result.data.system,
                logs: result.data.logs,
              });

            }
            else alert(result.message);

          });

        }
        else {
          console.log('Cannot register');
        }
      })

    })
  }


  hideModal() {
    this.setState({ showModal: false }, () => {
    });
  }


  render() {
    const { loading, logined, username, showModal, system, member, logs } = this.state;

    let banks = system.accounts

    return (
      <>


        {logined ? (
          <>


            {member.account &&
              <>

                <Layout>
                  <Row className="justify-content-md-center ">
                    <Col sm={12} lg={6} xs={12} >
                      <h1>เติมเครดิต</h1>
                      <Card className="my-4">
                        <Card.Body>
                          <p>ยินดีต้อนรับ <b>{username}</b></p>
                บัญชีของคุณ
                <span className="text-success mx-2">{member.account.number}</span>
                (<span className="text-success mx-2">{member.firstname} {member.lastname}</span>)

                ธนาคาร {member.account.bank.name}
                        </Card.Body>
                      </Card>

                      <Row className="justify-content-md-center ">
                        <Col sm={12} className="login-form text-center">
                          <img src={require('../assets/images/line-qrcode.jpg')} alt={'Line @' + system.configuration.line} />
                          <br />
                          <a href={'http://line.me/ti/p/@' + system.configuration.line} ><h3>@{system.configuration.line}</h3></a>
                        </Col>
                      </Row>

                      {system.accounts &&
                        <>
                          {banks.map((account, i) => (
                            <Card className="my-1" key={i}>
                              <Card.Body>
                                <Row>
                                  <Col xs={3} sm={3} lg={2}>
                                    <Image src={LOGO[account.bank.id]} fluid />
                                  </Col>
                                  <Col>
                                    <b>ธนาคาร {account.bank.name}</b>  <br />
                  เลขที่บัญชี {account.number} <br />
                  ชื่อบัญชี {account.name} <br />
                                  </Col>
                                </Row>
                              </Card.Body>
                            </Card>
                          ))}
                        </>
                      }

                      <Card className="my-4">
                        <Card.Body>
                          <div className="mb-2">
                            <b>รายการล่าสุด</b>
                          </div>

                          {loading ? (<FontAwesomeIcon icon={faSpinner} size="3x" spin />) : (

                            <Table striped hover className="table-sm">
                              <thead>
                                <tr className="text-center">
                                  <th>วันที่</th>
                                  <th >ประเภท</th>
                                  <th>จำนวนเงิน</th>
                                  <th width="120">สถานะ</th>
                                </tr>
                              </thead>
                              <tbody>
                                {logs.map((log, i) => (
                                  <tr key={i} className="text-center">
                                    <td>{log.createdAt}</td>
                                    <td>{log.type}</td>
                                    <td>{log.credit} บาท</td>
                                    <td><span className={'text-' + log.status.mode}>{log.status.title}</span></td>
                                  </tr>
                                ))}
                              </tbody>
                            </Table>

                          )}

                        </Card.Body>
                      </Card>

                    </Col>
                  </Row>

                </Layout>

                <Modal
                  show={showModal}
                  aria-labelledby="contained-modal-title-vcenter"
                  onHide={this.hideModal}
                  centered
                >
                  <Modal.Body className="text-center">

                    <FontAwesomeIcon className="mb-4" icon={faInfoCircle} size="6x" />

                    <h4 className="text-info mb-4">โปรดอ่าน</h4>
                    <p>
                      กรุณาใช้บัญชี <span className="text-success">{member.account.number}</span> (<span className="text-success">{member.firstname} {member.lastname}</span>) ในการโอนเงินเท่านั้นไม่เช่นนั้นเครดิตจะไม่เข้าบัญชี หากมีปัญหากรุณาติดต่อทีมงาน
            </p>

                    <div className="text-center">

                      <Button variant="secondary" className="px-4" onClick={this.hideModal}>ปิด</Button>
                    </div>
                  </Modal.Body>
                </Modal>
              </>
            }

          </>
        ) : (<Redirect to="/signin" />)}



      </>
    );
  }
}


const mapStateToProps = state => ({
  loading: state.loading
});

export default connect(mapStateToProps)(Deposit);