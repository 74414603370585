import axios from 'axios';
import Cookies from 'universal-cookie';

import config from '../config';

let mode = process.env.NODE_ENV || 'development';
mode = 'production'

const CONFIG = config[ mode ];

const cookies = new Cookies();

axios.interceptors.response.use(
  response => response,
  error => {
    //console.error(error);
    return Promise.reject({ 
      status: (error.response && error.response.status)?error.response.status:null , 
      data: (error.response && error.response.data)?error.response.data:null , 
    });
  }
);


const get = async (path, data = {}, headers = {}) => {
  if(data && Object.keys(data).length > 0){
    let args = [];
    for(let i in data) args.push(i+'='+data[i]);

    path += '?'+args.join('&');
  }

  return await axios.get( url(path), header( headers ) );
}

const post = async (path, data, headers = {}) => {
  return await axios.post( url(path) , data, header( headers ) );
}

const put = async (path, data, headers = {}) => {
  return await axios.put( url(path) , data, header( headers ) );
}


function url( path ){
  //console.log(CONFIG.api.url + path)
  return CONFIG.api.url + path;
}

function header( h ){

  h['x-api-key'] = CONFIG.api.key;

  if(cookies.get('ufasystem')){
    let data = cookies.get('ufasystem');
    h['authorization'] = 'Bearer '+data.token;
  }

  if(cookies.get('ufasystemdownline')){
    let data = cookies.get('ufasystemdownline');
    h['x-ufasystem'] = data.code.replace('@','');
  }


  return { headers:h };
}


export default {
  get,
  post,
  put,
}