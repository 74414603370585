import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import  { Redirect } from 'react-router-dom';
import Cookies from 'universal-cookie';

import '../assets/css/login.css'

import request from '../libs/request';

import Layout from '../component/layout';


const cookies = new Cookies();


class Login extends React.Component {
  constructor(props){
    super(props);



    this.state = {
      loading:false,
      username:'',
      password: '',
      success: false,
    }

    this.submitForm = this.submitForm.bind(this);
    this.changeValue = this.changeValue.bind(this);
  }


  componentDidMount(){
    if(cookies.get('ufasystem')) cookies.remove('ufasystem');
  }

  changeValue(e){
    let id = e.currentTarget.id.split('_').pop();
    let data = {...this.state};
    data[id] = e.currentTarget.value || '';
    this.setState( data );
  }


  submitForm(e){
    e.preventDefault();

    this.setState({ loading:true, success:false }, () => {

      request.post('member/login', this.state).then(response => {
        if(response.data && response.data.status_code === 200){

          let result = response.data;
 
          this.setState({ loading:false }, () => {

            if(result.success){
              let token = result.data.token;
              let username = result.data.username;

              cookies.set('ufasystem', { token, username }, { maxAge: 1 * 60 * 60 } );

              this.setState({ success:true });
            }
            else alert(result.message);

          });
          
        }
        else
        {
          console.log('Cannot register');
        }
      })

    })
  }


  render() {

    return (
      <>

      <Layout>

      <Row className="justify-content-md-center ">
        <Col sm={3} className="login-form">
          forget password
        </Col>
      </Row>

      </Layout>
      
      {this.state.success && <Redirect to='/'  /> }
      </>
    );
  }
}


const mapStateToProps = state => ({
  loading: state.loading
});

export default connect(mapStateToProps)(Login);