const init = false;

const reducer = (state = init, action) => {
  switch (action.type) {
    case 'LOGIN':
      return action.value;
  
    default: return state;
  }
}


export default reducer;