import React from 'react';
import { Row, Col, Card, Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import  { Redirect } from 'react-router-dom';
import Cookies from 'universal-cookie';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import _ from 'lodash';
//import CryptoJS from 'crypto-js';

import '../assets/css/main.css'


import request from '../libs/request';
import Layout from '../component/layout';

//import config from '../config';
//const CONFIG = config[ process.env.NODE_ENV || 'development' ];


const UFABET_URL = [
  'http://ufa6666.com', 'http://ufa7777.com', 'http://ufa9999.com', 'http://uf99999.com', 'http://kingbeer.net'
]

const url_ufabet = UFABET_URL[_.random(0, UFABET_URL.length - 1)];


const cookies = new Cookies();

class Main extends React.Component {
  constructor(props){
    super(props);

    let data = cookies.get('ufasystem');
    

    let refer = this.props.location.search.replace('?','');
    if(this.props.location.search){
      if(refer.substring(0,1).toLowerCase() === '@'){
        if(cookies.get('ufasystemdownline')) cookies.remove('ufasystemdownline');
        cookies.set('ufasystemdownline', { code:refer } );
      }
    }

    this.state = {
      loading: false,
      logined: data?true:false,
      username: data?data.username:'',
      //password: data?(CryptoJS.AES.decrypt(data.password, CONFIG.secret)).toString(CryptoJS.enc.Utf8):'',
      //password: 'Pwd03205',
      credit: 0,
      goDeposit: false,
      loadingUFABET: false,
      ufabet_fields: [],
    }

    //console.log('password', (CryptoJS.AES.decrypt(data.password, CONFIG.secret)).toString(CryptoJS.enc.Utf8) )

    this.loginUfabet = React.createRef();

    this.goDeposit = this.goDeposit.bind(this);
    this.enterUFABET = this.enterUFABET.bind(this)
  }

  componentDidMount(){

    this.setState({ loading:true }, () => {

      request.get('credit').then(response => {
        if(response.data && response.data.status_code === 200){

          let result = response.data;
 
          this.setState({ loading:false }, () => {

            if(result.success){

              this.setState({ credit: result.data.credit }, () => {
                this.props.dispatch({ type:'CREDIT', value:this.state.credit })
              });
              
            }
            else alert(result.message);

          });
          
        }
        else
        {
          console.log('Cannot register');
        }
      })

    })
  }

  goDeposit(){
    this.setState({ goDeposit:true })
  }

  enterUFABET(){
    
    let url = url_ufabet+'/Default8.aspx?lang=EN-GB';
    //console.log(url)
    //this.loginUfabet.current.click();

    this.setState({ loadingUFABET:true }, () => {

      request.post('ufabet/access', { url }).then(response => {
        if(response.data && response.data.status_code === 200){
  
          let result = response.data;
  
          this.setState({ loadingUFABET:false }, () => {
  
            if(result.success){
  
              if(result.data.url) window.open(result.data.url);
              
              /*
              if(result.data.fields && result.data.fields.length > 0){
                this.setState({ ufabet_fields:result.data.fields }, () => {
                  this.loginUfabet.current.submit();
                })
              }
              else
              {
                window.open(url);
              }
              */
            
            }
            else
            {
              console.log(result.message);
              window.open(url);
            }
  
          });
          
        }
        else
        {
          console.log('Cannot access');
          window.open(url);
        }
      })

    })

  }

  render() {
    const { loading, loadingUFABET, logined, username, credit, goDeposit, ufabet_fields } = this.state;

    
    return (
      <>
      
      <Layout>
      {logined?(
        <>
        <Row className="justify-content-md-center ">
          <Col sm={12} lg={6} xs={12} >
            
            <Card className="my-4">
              <Card.Body>
                ยินดีต้อนรับ <b>{username}</b>
              </Card.Body>
            </Card>

            <Card className="my-4">
              <Card.Body>
                <div>เครดิตปัจจุบัน </div>

                <h2 className="text-center my-2">
                {loading?(
                <FontAwesomeIcon icon={faSpinner} spin /> 
                ):(
                  <>{credit} บาท</>
                )}
                </h2>

              </Card.Body>
            </Card>

            <div className="my-4">
              <Button variant="primary" size="lg" block onClick={this.enterUFABET}>
              {loadingUFABET && <FontAwesomeIcon icon={faSpinner} spin />}  เข้าเล่น UFABET
              </Button>
            </div>

            <div className="my-4">
              <Button size="lg" variant="success" block onClick={this.goDeposit}>เติมเครดิต</Button>
            </div>

          </Col>
        </Row>
        
        <form name="frmufabet" ref={this.loginUfabet} method="post" rel="nofollow" action={url_ufabet+'/Default8.aspx?lang=EN-GB'} target="_blank" style={{display:'none'}} >
          {ufabet_fields.map((field,i) => (
            <input type="text" key={i} name={field.name} defaultValue={field.value} />
          ))}
          <input name="txtUserName" type="text" id="txtUserName" defaultValue={this.state.username}></input>
          <input name="password" type="password" id="password" defaultValue={this.state.password} />
          <button type="submit" ></button>
        </form>

        </>
      ):( <Redirect to="/signin" />)}


      {goDeposit && <Redirect to="/deposit" />}
      </Layout>
      
      </>
    );
  }
}


const mapStateToProps = state => ({
  loading: state.loading
});

export default connect(mapStateToProps)(Main);