import React from 'react';
import { Row, Col, Form, Button, Alert } from 'react-bootstrap';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { Redirect } from 'react-router-dom';
import Cookies from 'universal-cookie';

import '../assets/css/login.css'

import request from '../libs/request';

import Layout from '../component/layout';


const cookies = new Cookies();


class Login extends React.Component {
  constructor(props) {
    super(props);


    let reference = '';
    let refer = this.props.location.search.replace('?', '');
    if (this.props.location.search) {
      if (refer.substring(0, 1).toLowerCase() === '@') {
        if (cookies.get('ufasystemdownline')) cookies.remove('ufasystemdownline');
        cookies.set('ufasystemdownline', { code: refer });
      }
      else reference = refer;
    }


    this.state = {
      loading: false,
      username: '',
      password: '',
      success: false,
      reference: reference,
      system: null,
    }

    this.submitForm = this.submitForm.bind(this);
    this.changeValue = this.changeValue.bind(this);
  }


  componentDidMount() {
    if (cookies.get('ufasystem')) cookies.remove('ufasystem');



    this.setState({ loading: true, success: false }, () => {

      request.get('system').then(response => {
        if (response.data && response.data.status_code === 200) {

          let result = response.data;

          this.setState({ loading: false }, () => {

            if (result.success) {

              this.setState({ system: result.data.system })
            }
            else alert(result.message);

          });

        }
        else {
          console.log('Cannot register');
        }
      })

    })
  }

  changeValue(e) {
    let id = e.currentTarget.id.split('_').pop();
    let data = { ...this.state };
    data[id] = e.currentTarget.value || '';
    if (data[id]) {
      data[id] = data[id].replace(/\s+/g, '');
      if (id === 'username') data[id] = data[id].toLowerCase();
    }
    this.setState(data);
  }


  submitForm(e) {
    e.preventDefault();

    this.setState({ loading: true, success: false }, () => {

      request.post('member/login', this.state).then(response => {
        if (response.data && response.data.status_code === 200) {

          let result = response.data;

          this.setState({ loading: false }, () => {

            if (result.success) {
              let token = result.data.token;
              let username = result.data.username;

              cookies.set('ufasystem', { token, username }, { maxAge: 1 * 60 * 60 });

              this.setState({ success: true });
            }
            else {
              alert(result.message);
            }

          });

        }
        else {
          console.log('Cannot register');
        }
      })

    })
  }


  render() {
    const { loading, system, username, password } = this.state;

    return (
      <>

        {system &&
          <>

            {system.configuration.maintenance &&
              <>
                <Row className="justify-content-md-center ">
                  <Col sm={4}  >
                    <Alert variant="warning">
                      <div dangerouslySetInnerHTML={{ __html: system.configuration.message ? system.configuration.message.maintenance || 'ปิดปรับปรุงระบบ' : 'ปิดปรับปรุงระบบ' }}></div>
                    </Alert>
                  </Col>
                </Row>
              </>
            }

            {!system.configuration.maintenance &&
              <>
                <Layout>

                  <Row className="justify-content-md-center ">
                    <Col sm={3} className="login-form">
                      <Form onSubmit={this.submitForm}>
                        <Form.Group controlId="login_username">
                          <Form.Label>ชื่อผู้ใช้ระบบ</Form.Label>
                          <Form.Control type="text" placeholder="" value={username} required onChange={this.changeValue} />
                        </Form.Group>

                        <Form.Group controlId="login_password">
                          <Form.Label>รหัสผ่าน</Form.Label>
                          <Form.Control type="password" placeholder="" value={password} required onChange={this.changeValue} />
                        </Form.Group>


                        {loading ? (
                          <div className="text-center">
                            <FontAwesomeIcon icon={faSpinner} size="3x" spin /> <br />
                กรุณารอสักครู่...
                          </div>
                        ) : (
                            <Button variant="primary" type="submit" block>
                              เข้าสู่ระบบ
                            </Button>
                          )}

                      </Form>
                    </Col>
                  </Row>

                </Layout>
              </>
            }

          </>
        }

        {this.state.success && <Redirect to='/' />}
      </>
    );
  }
}


const mapStateToProps = state => ({
  loading: state.loading
});

export default connect(mapStateToProps)(Login);