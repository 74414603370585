import React from 'react';
import { Row, Col, Card, Table, InputGroup, FormControl, Button, Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import Cookies from 'universal-cookie';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'

import '../assets/css/main.css'

import request from '../libs/request';
import Layout from '../component/layout';

const cookies = new Cookies();
const PIN_TIMEOUT = 5 * 60;
var start_count;

class Withdraw extends React.Component {
  constructor(props) {
    super(props);

    let data = cookies.get('ufasystem');

    this.state = {
      loading: false,
      logined: data ? true : false,
      username: data ? data.username : '',
      system: {},
      member: {},
      logs: [],
      withdraw_min: 100,
      withdraw_credit: 0,
      withdraw_pin: '',
      withdrawid: '',
      counter: 0,
      showWarn: false,
      showConfirm: false,
      showPin: false,
    }

    this.hideWarn = this.hideWarn.bind(this);
    this.hideConfirm = this.hideConfirm.bind(this);
    this.hidePin = this.hidePin.bind(this);
    this.withdrawValue = this.withdrawValue.bind(this);
    this.withdraw = this.withdraw.bind(this);
    this.pinValue = this.pinValue.bind(this);
    this.confirmWithdraw = this.confirmWithdraw.bind(this);
    this.confirmPin = this.confirmPin.bind(this);
    this.expirePin = this.expirePin.bind(this);
  }

  componentDidMount() {

    this.loadData();

  }

  loadData() {
    this.setState({ loading: true }, () => {

      request.get('page/withdraw').then(response => {
        if (response.data && response.data.status_code === 200) {

          let result = response.data;

          this.setState({ loading: false }, () => {

            if (result.success) {

              this.setState({
                member: result.data.member,
                system: result.data.system,
                logs: result.data.logs,
                withdraw_credit: result.data.member.credit || 0,
                withdraw_min: (result.data.withdraw_min < 50) ? 50 : result.data.withdraw_min,
              });

            }
            else alert(result.message);

          });

        }
        else {
          console.log('ไม่สามารถอนเงินได้ขณะนี้');
        }
      })

    })
  }

  withdrawValue(e) {
    let credit = e.currentTarget.value || 0;
    this.setState({ withdraw_credit: credit });
  }

  withdraw() {
    let credit = this.state.withdraw_credit;

    if (credit > 0 && credit <= this.state.member.credit && credit >= this.state.withdraw_min) {

      this.setState({ showConfirm: true });
    }
    else {
      this.setState({ showWarn: true })
    }

  }

  hideWarn() {
    this.setState({ showWarn: false });
  }

  hideConfirm() {
    this.setState({ showConfirm: false });
  }

  hidePin() {
    if (start_count) clearInterval(start_count);

    this.setState({ showPin: false }, () => {
      if (this.state.withdrawid && this.state.counter > 0) {
        this.expirePin();
      }
    });
  }

  confirmWithdraw() {

    let credit = this.state.withdraw_credit;

    if (credit > 0 && credit <= this.state.member.credit) {


      this.setState({ loading: true, showConfirm: false, withdrawid: '', counter: 0 }, () => {

        request.post('credit/withdraw', { credit: this.state.withdraw_credit }).then(response => {
          if (response.data && response.data.status_code === 200) {

            let result = response.data;

            this.setState({ loading: false }, () => {

              if (result.success) {

                let withdrawid = result.data.id;

                this.setState({ withdrawid, showPin: true, counter: PIN_TIMEOUT }, () => {

                  start_count = setInterval(() => {
                    if (this.state.counter > 0) {
                      let counter = this.state.counter - 1;
                      this.setState({ counter });
                    }
                    else {
                      clearInterval(start_count); start_count = null;
                      alert('หมดเวลาการทำรายการ กรุณาทำรายการใหม่อีกครั้ง');
                      this.expirePin();
                    }
                  }, 1000);

                })
                //alert('แจ้งถอนเงินเรียบร้อยแล้ว');
                //this.loadData();

              }
              else alert(result.message);

            });

          }
          else {
            console.log('ไม่สามารถอนเงินได้ขณะนี้');
          }
        })

      })

    }
    else {
      this.setState({ showWarn: true })
    }
  }


  pinValue(e) {
    let pin = e.currentTarget.value || 0;
    this.setState({ withdraw_pin: pin });
  }

  confirmPin() {
    if (this.state.withdrawid) {
      if (start_count) clearInterval(start_count);

      this.setState({ loading: true }, () => {
        request.post('credit/pin/confirm', { id: this.state.withdrawid, pin: this.state.withdraw_pin }).then(response => {

          this.setState({ loading: false, withdrawid: '', counter: 0 }, () => {
            if (response.data && response.data.status_code === 200) {

              let result = response.data;

              if (result.success) {
                this.setState({ withdrawid: '', counter: 0 }, () => {

                  alert('ทำรายการแจ้งถอนเงินเรียบร้อยแล้ว');
                  this.loadData();
                  this.hidePin();

                });

              }
              else alert(result.message);

            }
            else {
              alert('ไม่สามารถอนเงินได้ขณะนี้');
            }
          })
        })
      })
    }
  }

  expirePin() {
    if (this.state.withdrawid) {

      this.setState({ loading: true, showPin: false }, () => {
        request.post('credit/pin/expire', { id: this.state.withdrawid }).then(response => {
          this.setState({ loading: false }, () => {
            this.loadData();
          })
        })
      })
    }
  }

  render() {
    const { loading, withdraw_min, logined, username, member, logs, withdraw_credit, withdraw_pin, counter, showWarn, showConfirm, showPin } = this.state;

    return (
      <>


        {logined ? (
          <>


            {member.account &&
              <>

                <Layout>
                  <Row className="justify-content-md-center ">
                    <Col sm={12} lg={6} xs={12} >
                      <h1>ถอนเงิน</h1>
                      <Card className="my-4">
                        <Card.Body>
                          ยินดีต้อนรับ <b>{username}</b>
                        </Card.Body>
                      </Card>

                      <Card>
                        <Card.Body>
                          <div className="mb-2">ยอดเครดิต {member.credit} บาท</div>

                          {loading ? (<FontAwesomeIcon icon={faSpinner} size="2x" spin />) : (
                            <InputGroup className="mb-3">
                              <InputGroup.Prepend>
                                <InputGroup.Text>จำนวนเงิน</InputGroup.Text>
                              </InputGroup.Prepend>
                              <FormControl
                                type="number"
                                placeholder={'ระบุจำนวนเงินที่ต้องการถอน ขั้นต่ำ ' + withdraw_min + ' บาท'}
                                max={member.credit || 0}
                                min={withdraw_min}
                                onChange={this.withdrawValue}
                                disabled={(member.credit === 0)}
                              />
                              <InputGroup.Append>
                                <Button variant="success" onClick={this.withdraw} disabled={(member.credit === 0)}>ถอนเงิน</Button>
                              </InputGroup.Append>
                            </InputGroup>
                          )}

                        </Card.Body>
                      </Card>


                      <Card className="my-4">
                        <Card.Body>
                          <div className="mb-2">
                            <b>รายการล่าสุด</b>
                          </div>

                          {loading ? (<FontAwesomeIcon icon={faSpinner} size="3x" spin />) : (

                            <Table striped hover className="table-sm">
                              <thead>
                                <tr className="text-center">
                                  <th>จำนวนเงิน</th>
                                  <th>วันที่แจ้งถอน</th>
                                  <th width="120">สถานะ</th>
                                </tr>
                              </thead>
                              <tbody>
                                {logs.map((log, i) => (
                                  <tr key={i} className="text-center">
                                    <td>{log.credit} บาท</td>
                                    <td>{log.createdAt}</td>
                                    <td><span className={'text-' + log.status.mode}>{log.status.title}</span></td>
                                  </tr>
                                ))}
                              </tbody>
                            </Table>

                          )}

                        </Card.Body>
                      </Card>

                    </Col>
                  </Row>
                </Layout>

                <Modal
                  show={showWarn}
                  aria-labelledby="contained-modal-title-vcenter"
                  onHide={this.hideWarn}
                  centered
                >
                  <Modal.Body className="text-center">

                    <FontAwesomeIcon className="mb-4 text-danger" icon={faExclamationTriangle} size="6x" />

                    <h4 className="text-info mb-4">จำนวนเครดิตไม่ถูกต้อง</h4>
                    <h4 className="text-warning mb-4">(จะต้องมากกว่า {withdraw_min} และไม่เกิน {member.credit} บาท)</h4>

                    <div className="text-center">

                      <Button variant="secondary" className="px-4" onClick={this.hideWarn}>ปิด</Button>
                    </div>
                  </Modal.Body>
                </Modal>


                <Modal
                  show={showConfirm}
                  aria-labelledby="contained-modal-title-vcenter"
                  onHide={this.hideConfirm}
                  centered
                >
                  <Modal.Body className="text-center">


                    <h4 className="text-info mb-4">ยืนยันการถอนเงิน</h4>
                    <h4 className=" mb-4">ถอนเงิน <span className="text-info">{withdraw_credit}</span> บาท</h4>
                    <h4 className=" mb-4">เข้าบัญชี <span className="text-success">{member.account.number}</span> (<span className="text-success">{member.firstname} {member.lastname}</span>)</h4>

                    <div className="text-center">

                      <Button variant="outline-secondary" className="mr-4" onClick={this.hideConfirm}>ยกเลิก</Button>
                      <Button variant="primary" className="px-4" onClick={this.confirmWithdraw}>ยืนยัน</Button>
                    </div>
                  </Modal.Body>
                </Modal>

                <Modal
                  show={showPin}
                  aria-labelledby="contained-modal-title-vcenter"
                  onHide={this.hidePin}
                  backdrop="static"
                  keyboard={false}
                  centered
                >
                  <Modal.Body className="text-center">

                    <div className="mb-4">
                      <h4 >กรุณากรอก รหัสยืนยัน 6 หลัก จาก SMS</h4>
                    </div>

                    <FormControl
                      className="mb-4 text-center"
                      type="text"
                      size="lg"
                      placeholder=""
                      value={withdraw_pin}
                      onChange={this.pinValue}
                      maxLength={6}
                      minLength={6}
                    />

                    <div className="mb-4">
                      <h5 className="text-warning">รหัสยืนยันจะหมดอายุภายใน {counter}</h5>
                    </div>

                    {loading ? (<FontAwesomeIcon icon={faSpinner} size="3x" spin />) : (
                      <div className="text-center">

                        <Button variant="outline-secondary" className="mr-4" onClick={this.hidePin}>ยกเลิก</Button>
                        <Button variant="primary" className="px-4" onClick={this.confirmPin}>ยืนยัน</Button>

                      </div>
                    )}

                  </Modal.Body>
                </Modal>
              </>
            }

          </>
        ) : (<Redirect to="/signin" />)}



      </>
    );
  }
}


const mapStateToProps = state => ({
  loading: state.loading,
  credit: state.credit,
});

export default connect(mapStateToProps)(Withdraw);