import React from 'react';
import { Navbar, Nav, Container } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Link, Redirect } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignInAlt, faUser, faUserPlus, faKey, faSignOutAlt, faHandHoldingUsd, faMoneyBillWave, faWallet } from '@fortawesome/free-solid-svg-icons'

import Cookies from 'universal-cookie';

import request from '../libs/request';


const cookies = new Cookies();

class Layout extends React.Component {

  constructor(props){
    super(props);

    let data = cookies.get('ufasystem');

    this.state = {
      loading:false,
      logined: data?true:false,
      logout:false,
    }

    this.logout = this.logout.bind(this);
  }


  logout(){
    request.post('member/logout').then(response => {

      cookies.remove('ufasystem');
      this.setState({ logined:false, logout:true });

    })

  }

  render() {
    const { logined, logout } = this.state;

    return (
      <>

      {logout && <Redirect to="/signin" />}

      <Navbar bg="dark" variant="dark" fixed="top">
        <Container>
          <Navbar.Brand href="#home">UFASystem</Navbar.Brand>
        </Container>
      </Navbar>
      {this.props.children}
      <Navbar bg="dark" variant="dark" fixed="bottom">
        <Container>

        {logined?(
          <Nav fill >
            <Nav.Item>
              <Link to="/" className="nav-link">
                <FontAwesomeIcon icon={faUser} /><br/>บัญชีผู้ใช้
              </Link>
            </Nav.Item>
            <Nav.Item>
              <Link to="/deposit"  className="nav-link">
                <FontAwesomeIcon icon={faWallet} /><br/>เติมเครดิต
              </Link>
            </Nav.Item>
            <Nav.Item>
              <Link to="/withdraw"  className="nav-link">
                <FontAwesomeIcon icon={faMoneyBillWave} /><br/>ถอนเงิน
              </Link>
            </Nav.Item>
            <Nav.Item>
              <Link to="/refer"  className="nav-link">
                <FontAwesomeIcon icon={faHandHoldingUsd} /><br/>รับเครดิตฟรี
              </Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link onClick={this.logout}>
                <FontAwesomeIcon icon={faSignOutAlt} /><br/>ออกจากระบบ
              </Nav.Link>
            </Nav.Item>
          </Nav>
        ):(
          <Nav fill >
            <Nav.Item className="mx-4">
              <Link to="/signin"  className="nav-link">
                <FontAwesomeIcon icon={faSignInAlt} /><br/>เข้าสู่ระบบ
              </Link>
            </Nav.Item>
            <Nav.Item className="mx-4">
              <Link to="/register"  className="nav-link">
                <FontAwesomeIcon icon={faUserPlus} /><br/>สมัครสมาชิก
              </Link>
            </Nav.Item>
            <Nav.Item className="mx-4">
              <Link to="/forgetpassword"  className="nav-link">
                <FontAwesomeIcon icon={faKey} /><br/>ลืมรหัสผ่าน
              </Link>
            </Nav.Item>
          </Nav>
          )}
        </Container>
      </Navbar>
      </>
    );
  }
}




const mapStateToProps = state => ({
  loading: state.loading,
});

export default connect(mapStateToProps)(Layout);