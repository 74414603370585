import { combineReducers } from 'redux';

import Loading from './loading';
import Credit from './credit';
import Login from './login';


export default combineReducers({
  loading: Loading,
  credit: Credit,
  login: Login,
})